.banner-background {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../assets/img/banner.jpg");
  background-size: auto;
  background-position-y: -250px;
}

@media (min-width: 1281px) {
  .banner-container {
    margin-top: -75px !important;
  }

  .banner-title {
    font-size: 6em !important;
  }

  .banner-subtitle {
    font-size: 5em !important;
  }
}
