.no-max-height {
  max-height: 3em !important;
}

.no-padding {
  padding: 0 !important;
}

@media only screen and (max-width: 800px) {
  .navbar-item {
    display: inline;
    font-size: 80%;
    padding: 0.25rem 0.45rem;
  }
}
